import {ChevronLeft, ChevronRight} from '@wix/wix-ui-icons-common'
import classNames from 'classnames'
import React from 'react'
import {CalendarLoading} from '../../../../types/state'
import {MainListTitle} from '../../../main-list-title'
import {Spinner} from '../../../spinner'
import {TodayButton} from '../../today-button'
import {MobileMonthlyDays} from './days'
import s from './mobile-monthly-calendar.scss'
import {MobileMonthlyCalendarProps} from '.'

export const MobileMonthlyCalendar: React.FC<MobileMonthlyCalendarProps> = ({
  subCalendarMonth,
  addCalendarMonth,
  resetCalendar,
  fullMonthText,
  loading,
}: MobileMonthlyCalendarProps) => (
  <div data-hook="monthly-mobile-calendar">
    <MainListTitle />
    <div className={s.controls}>
      <div className={s.monthControls}>
        <button
          onClick={() => subCalendarMonth()}
          type="button"
          className={classNames(s.back, s.monthButton)}
          data-hook="calendar-previous-month-button"
        >
          <ChevronLeft size="1.5em" />
        </button>
        <div className={s.month} data-hook="calendar-month">
          {loading === CalendarLoading.NEXT || loading === CalendarLoading.PREVIOUS ? (
            <Spinner diameter={20} calendar />
          ) : (
            fullMonthText
          )}
        </div>
        <button
          onClick={() => addCalendarMonth()}
          type="button"
          className={classNames(s.next, s.monthButton)}
          data-hook="calendar-next-month-button"
        >
          <ChevronRight size="1.5em" />
        </button>
      </div>
      <TodayButton
        onClick={resetCalendar}
        loading={loading === CalendarLoading.DEFAULT}
        dataHook="calendar-today-button"
      />
    </div>
    <div className={s.days}>
      <MobileMonthlyDays />
    </div>
  </div>
)
